import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Title = styled(Typography)({
  zIndex: 1,
  fontSize: '32px',
  fontWeight: '700',
  textTransform: 'uppercase',
  marginTop: 5,
  marginBottom: 1,
  fontFamily: 'Rajdhani',
});
