import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import RajdhaniMedium from './assets/fonts/Rajdhani-Medium.ttf';
import RajdhaniBold from './assets/fonts/Rajdhani-Bold.ttf';

let theme = createTheme({
  palette: {
    background: {
      default: '#f6f7f8',
    },
  },
  typography: {
    fontFamily: ['Rajdhani', 'sans-serif'].join(','),
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Rajdhani';
          font-style: normal;
          font-weight: 500;
          src: local('Rajdhani'), local('Rajdhani-Medium'), url(${RajdhaniMedium}) format('truetype');
        }
        @font-face {
          font-family: 'Rajdhani';
          font-style: normal;
          font-weight: 700;
          src: local('Rajdhani'), local('Rajdhani-Bold'), url(${RajdhaniBold}) format('truetype');
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
