import axios from 'axios';
import { API_URL } from './config';
import { headerConfig } from './Firebase';

export interface SystemCustomClaims {
  division?: string;
  isAdmin: boolean;
  permissions?: string;
}

export interface SystemFirebaseUser {
  uid: string;
  email: string;
  emailVerified: boolean;
  displayName: string;
  photoURL?: string;
  phoneNumber?: string;
  disabled: boolean;
  customClaims?: SystemCustomClaims;
}

export type Bot = {
  id: number;
  name: string;
};

export type Chat = {
  id: number;
  bot: Bot;
  uuid: string;
};

export type ChatMessage = {
  id: number;
  text: string;
  uuid: string;
  uid: string;
  chat_id: number;
  timestamp: number;
  translation?: string;
  isBlunder: boolean;
};

export type ChatResponse = {
  response: ChatMessage;
  translations: string[];
};

export type TemporaryChatMessage = {
  text: string;
  uid: string;
  chat_id: number;
  timestamp: number;
  translation?: string;
  isBlunder?: boolean;
};

export type StartChatResponse = {
  chat: Chat;
  messages: ChatMessage[];
};


export const startChat = async (language: string): Promise<StartChatResponse> => {
  const response = await axios.post(`${API_URL}/start`, { language }, await headerConfig());
  return response.data as StartChatResponse;
};

export const sendMessage = async (message: string, chat_uuid: string): Promise<ChatResponse> => {
  const response = await axios.post(`${API_URL}/chats/${chat_uuid}`, { message: message }, await headerConfig());
  return response.data as ChatResponse;
};

export type ChatRecommendationsResponse = {
  recommendations: string[];
};

export const getRecommendations = async (
  search_text: string,
  chat_uuid: string,
): Promise<ChatRecommendationsResponse> => {
  const response = await axios.post(
    `${API_URL}/chats/${chat_uuid}/recommendations`,
    { text: search_text },
    await headerConfig(),
  );
  return response.data as ChatRecommendationsResponse;
};

export type SpellcheckResponse = {
  input_text: string;
  isBlunder: boolean;
  isInaccuracy: boolean;
  correction?: string;
  description?: string;
}

export const getSpellcheck = async (
  text: string,
  chat_uuid: string,
): Promise<SpellcheckResponse> => {
  const response = await axios.post(
    `${API_URL}/chats/${chat_uuid}/spellcheck`,
    { text: text },
    await headerConfig(),
  );
  return response.data as SpellcheckResponse;
};
