import { Button, Grid, styled, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import axios from 'axios';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthCtx } from '../AuthProvider';
import { UIContext } from '../UIProvider';

export default function ProfilePage() {
  const { darkMode, windowSize } = React.useContext(UIContext);

  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        py: 2,
        // px: 2,
        // alignItems: windowSize.width > 500 ? 'center' : undefined,
        minHeight: '100vh',
        background: darkMode ? '#48a7c3' : '#48a7c3',
        color: 'white',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    ></Stack>
  );
}
