import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { ChangeEvent, FC, useContext, useState } from 'react';
import { UIContext } from '../UIProvider';

import HelpIcon from '@mui/icons-material/HelpOutlined';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Chat, getSpellcheck } from '../API';
import RecommendationView from '../pages/RecommendationView';
import { DataCtx } from '../pages/DataProvider';
import CircularProgress from '@mui/material/CircularProgress';
import SpellcheckModal from '../pages/SpellcheckDetailsModal';

interface ChatInputFieldProps {
  onSendMessage?: (message: string) => void;
  onVerifyMessage?: () => void;
  chat: Chat;
}

const ChatInputFiled: FC<ChatInputFieldProps> = ({ chat, onSendMessage }) => {
  const { darkMode } = useContext(UIContext);
  const { inputText, setInputText } = useContext(DataCtx);
  const [isBlunder, setIsBlunder] = useState(false);
  const [isInaccuracy, setIsInaccuracy] = useState(false);
  const [shouldCheckInputText, setShouldCheckInputText] = useState(false);
  const [spellcheckDescription, setSpellcheckDescription] = useState('');
  const [loadingCorrection, setLoadingCorrection] = useState(false);

  const [blunderCheckTimeout, setBlunderCheckTimeout] = useState<NodeJS.Timeout | null>(null);

  // Display recommendation view
  const [displayRecommendationView, setDisplayRecommendationView] = useState(false);

  // Display Spellcheck Description Modal
  const [displaySpellcheckDescription, setDisplaySpellcheckDescription] = useState(false);

  const handleSendMessage = () => {
    if (onSendMessage && inputText !== '') {
      onSendMessage(inputText);
    }
  };

  // Handle enter key press
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onSendMessage) {
      onSendMessage(inputText);
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newVal = event.target.value;
    setInputText(newVal);

    // Console log text changed only after deltatime has passed
    const delta = 1500;
    if (blunderCheckTimeout) {
      clearTimeout(blunderCheckTimeout);
    }

    const _shouldCheckInputText = newVal !== '' && newVal.length > 1;
    setShouldCheckInputText(_shouldCheckInputText);
    if (_shouldCheckInputText) {
      setLoadingCorrection(true);
      const timeout = setTimeout(async () => {
        setLoadingCorrection(true);
        const spellcheckResponse = await getSpellcheck(newVal, chat.uuid);
        setIsBlunder(spellcheckResponse.isBlunder);
        setIsInaccuracy(spellcheckResponse.isInaccuracy);
        if (spellcheckResponse.description) {
          setSpellcheckDescription(spellcheckResponse.description);
        }
        setLoadingCorrection(false);
      }, delta);
      setBlunderCheckTimeout(timeout);
    }
  };

  return (
    <>
      {chat && (
        <RecommendationView
          open={displayRecommendationView}
          onClose={() => setDisplayRecommendationView(false)}
          onUseRecommendation={recommendation => setInputText(recommendation)}
          chat_uuid={chat.uuid}
        />
      )}

      {
        <SpellcheckModal
          spellcheckDescription={spellcheckDescription}
          open={displaySpellcheckDescription}
          onClose={() => setDisplaySpellcheckDescription(false)}
        />
      }
      <OutlinedInput
        onChange={handleTextChange}
        // Listen for enter key press
        onKeyPress={handleKeyPress}
        value={inputText}
        sx={{
          color: darkMode ? 'white' : 'black',
          '.MuiInputBase-input': {
            // pl: 2
          },
        }}
        startAdornment={
          inputText === '' || !shouldCheckInputText ? (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setDisplayRecommendationView(true)}
                // onMouseDown={handleMouseDownPassword}
                // edge="start"
                sx={{
                  color: darkMode ? '#888' : 'black',
                }}
              >
                <HelpIcon />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position="start">
              {loadingCorrection ? (
                <IconButton
                  sx={{
                    color: darkMode ? '#888' : 'black',
                  }}
                >
                  <CircularProgress size={20} color="inherit" />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    color: isBlunder ? '#c1121f' : isInaccuracy ? '#ffb703' : '#b5e48c',
                  }}
                  onClick={() => setDisplaySpellcheckDescription(true)}
                >
                  {isBlunder ? <DangerousIcon /> : isInaccuracy ? <ErrorIcon /> : <CheckIcon />}
                </IconButton>
              )}
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleSendMessage}
              // onMouseDown={handleMouseDownPassword}
              // edge="end"
              sx={{
                color: darkMode ? 'white' : 'black',
                // mr: "0px"
              }}
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};
export default ChatInputFiled;
