import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UIContext } from '../UIProvider';

export default function LanguageSelectionPage() {
  const { darkMode, windowSize } = React.useContext(UIContext);

  const navigate = useNavigate();

  const navigateToChat = (language: string) => {
    navigate(`/${language}`);
  };

  return (
    <Stack
      sx={{
        flex: 1,
        py: 2,
        px: 2,
        alignItems: windowSize.width > 500 ? 'center' : undefined,
      }}
    >
      <Typography>what language do you want to practise?</Typography>
      <Stack spacing={2}>
        <Button variant="outlined" onClick={() => navigateToChat('en')}>
          English
        </Button>
        <Button variant="outlined" onClick={() => navigateToChat('fi')}>
          Finnish
        </Button>
        <Button variant="outlined" onClick={() => navigateToChat('es')}>
          Spanish
        </Button>
        <Button variant="outlined" onClick={() => navigateToChat('de')}>
          German
        </Button>
        <Button variant="outlined" onClick={() => navigateToChat('sv')}>
          Swedish
        </Button>
        <Button variant="outlined" onClick={() => navigateToChat('tr')}>
          Turkish
        </Button>
        <Button variant="outlined" onClick={() => navigateToChat('ru')}>
          Russian
        </Button>
      </Stack>
    </Stack>
  );
}
