import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';
import CSImg from '../assets/banner.jpg';
import { UIContext } from '../UIProvider';

const CoolStoryLogo = styled('div')({
  backgroundImage: `url(${CSImg})`,
  backgroundSize: 'contain',
  width: '400px',
  height: '200px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

export default function LoadingPage() {
  const { darkMode } = useContext(UIContext);
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: darkMode ? 'white' : 'white',
      }}
    >
      <CoolStoryLogo />
    </div>
  );
}
