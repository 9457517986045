import { Button, Grid, styled, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import axios from 'axios';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthCtx } from '../AuthProvider';
import { UIContext } from '../UIProvider';

import CoolStoryLogoBlack from '../assets/CoolStory_black.png';
import CoolStoryLogoWhite from '../assets/CoolStory_white.png';

export default function IndexPage() {
  const { darkMode, windowSize } = React.useContext(UIContext);

  const navigate = useNavigate();

  return (
    <>
      <Stack
        sx={{
          py: 2,
          // px: 2,
          // alignItems: windowSize.width > 500 ? 'center' : undefined,
          minHeight: '100vh',
          background: darkMode ? '#48a7c3' : '#48a7c3',
          color: 'white',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            height: '200px',
            maxWidth: '500px',
            minWidth: '400px',
            display: 'block',
            // border: '1px solid #fff',
            backgroundImage: `url(${darkMode ? CoolStoryLogoWhite : CoolStoryLogoBlack})`,
            // keep aspect ratio of background image
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            userSelect: 'none',
            backgroundPosition: 'center',
          }}
        />
        <Typography mt={2}>A fun and interactive way to learn a new language :)</Typography>
        {/* Get Started Button */}
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginTop: '40px' }}
          onClick={() => navigate('/login')}
        >
          Get Started
        </Button>

        {/* Already have an account button */}
        <Button
          variant="outlined"
          color="primary"
          size="large"
          sx={{ marginTop: '20px', color: 'white' }}
          onClick={() => navigate('/login')}
        >
          Already have an account?
        </Button>
      </Stack>

      {/* Footer section with different columns */}
      <Stack
        sx={{
          flex: 1,
          py: 5,
          px: 2,
          backgroundColor: darkMode ? '#1e1e1e' : '#f5f5f5',
          color: darkMode ? '#f5f5f5' : '#1e1e1e',
          minHeight: '400px',
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={3} p={2}>
            <Typography variant="h6">About</Typography>
            <Typography variant="body2">
              This is a chat app for practising languages. You can chat with an artificial intelligence in the language
              you want to practise.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} p={2}>
            <Typography variant="h6">Contact</Typography>
            <Typography variant="body2">
              If you have any questions or suggestions, please contact us at{' '}
              <a href="mailto:hello@coolstory.ai">hello@coolstory.ai</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} p={2}>
            <Typography variant="h6">Legal</Typography>
            <Typography variant="body2">This app is not affiliated with any company. All rights reserved.</Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
