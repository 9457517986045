import React from 'react';

export default function UnauthorizedPage() {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Unauthorized
    </div>
  );
}
