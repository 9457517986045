import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

let firebaseConfig = {
  apiKey: "AIzaSyDeb7RVFp1GP9WHW7wiVJ0Ua6p8xIGohRI",
  authDomain: "coolstoryai.firebaseapp.com",
  databaseURL: "https://coolstoryai.firebaseio.com",
  projectId: "coolstoryai",
  storageBucket: "coolstoryai.appspot.com",
  messagingSenderId: "722950569215",
  appId: "1:722950569215:web:2a9b49245288a2adca20ca",
  measurementId: "G-K2JFD382H2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

const headerConfig = async (): Promise<{ [key: string]: any }> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    return { headers: { Authorization: 'Bearer ' + token } };
  } else {
    return {};
  }
};

const uploadFileToStorage = async (
  fileName: string,
  storagePath: string,
  file: File,
  onLoadingHandler?: (progress: boolean) => void,
  onProgressHandler?: (progress: number) => void,
) => {
  if (onLoadingHandler) onLoadingHandler(true);

  const destPath = `${storagePath}/${fileName}`;

  const storageRef = ref(storage, destPath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (onProgressHandler) onProgressHandler(progress);
    },
    (error: any) => {
      console.log(error);
      if (onLoadingHandler) onLoadingHandler(false);
      if (onProgressHandler) onProgressHandler(-1);
    },
    async () => {
      if (onLoadingHandler) onLoadingHandler(false);
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        console.log('File available at', downloadURL);
      });
    },
  );
};

export { app, auth, database, firestore, storage, headerConfig, uploadFileToStorage };
