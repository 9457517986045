import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// FIREBASE
import { getAuth, OAuthProvider, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from 'firebase/auth';
import { ref, uploadBytes } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { storage, firestore } from '../Firebase';

// CUSTOM
import GooglSignInDark from '../assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png';
import GooglSignInLight from '../assets/google_signin_buttons/web/2x/btn_google_signin_light_normal_web@2x.png';

// MUI
import IconButton from '@mui/material/IconButton';
import { AuthCtx } from '../AuthProvider';
import { Button, styled, TextField } from '@mui/material';
import { UIContext } from '../UIProvider';
import { Stack } from '@mui/system';
import TopLeftCancelButton from '../components/TopLeftCancelButton';

interface WindowsLocationType {
  from: Location | undefined;
}

interface SignUpButtonProps {
  isSignUp: boolean;
  onButtonPress: () => void;
}

const SignUpButton: FC<SignUpButtonProps> = ({ isSignUp, onButtonPress }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      sx={{
        // position top right absolutely
        position: 'absolute',
        top: 0,
        right: 0,
      }}
      onClick={onButtonPress}
    >
      {isSignUp ? 'Sign Up' : 'Sign In'}
    </Button>
  );
};

const SignInWithGoogleButton = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  backgroundImage: `url(${GooglSignInDark})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: 192,
  height: 48,
});

export default function SignInPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const [isSignUp, setIsSignUp] = useState(false);

  let location = useLocation();
  let navigate = useNavigate();

  let state = location.state as WindowsLocationType | undefined;
  let from = state?.from?.pathname || '/';

  // CONTEXTS
  const { user } = React.useContext(AuthCtx);

  const { darkMode } = React.useContext(UIContext);

  useEffect(() => {
    if (user) {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      if (from === '/') {
        navigate('/learn', { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    }
  }, [from, navigate, user]);

  // https://gdpr.eu/cookies/
  // State variable to control whether to show the cookie consent modal.
  // const [showConsentRequest, setShowConsentRequest] = React.useState(false);
  // const [cookies, setCookie] = useCookies(['privacy_modal_displayed', 'privacy_consent_functional', 'preferences']);
  // const [loginErrorMsg, setLoginErrorMsg] = React.useState<string>('');

  const handleSignInWithGoogle = async () => {
    // Sign in using firebase google auth provider
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(result => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        // const user = result.user;
        // ...
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, username, password);
    } catch (error) {
      setError('Error signing in with password and email!');
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        background: darkMode ? '#48a7c3' : '#48a7c3',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      spacing={2}
    >
      <TopLeftCancelButton onButtonPress={() => navigate('/')} />
      <SignUpButton
        isSignUp={isSignUp}
        onButtonPress={() => {
          setIsSignUp(!isSignUp);
        }}
      />
      <TextField label="Username" value={username} onChange={event => setUsername(event.target.value)} />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={event => setPassword(event.target.value)}
      />
      {error && <p>{error}</p>}
      <Button type="submit">Sign In</Button>

      {/* Button to sign up with google */}
      <Stack direction={'row'}></Stack>

      <SignInWithGoogleButton
        onClick={() => handleSignInWithGoogle()}
        sx={{
          backgroundImage: `url(${darkMode ? GooglSignInDark : GooglSignInLight})`,
        }}
      />
      {/* <IconButton onClick={() => handleSignInWithGoogle()}>
        <LightMicrosoftSignIn />
      </IconButton> */}
    </Stack>
  );
}
