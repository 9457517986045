import { styled } from '@mui/material/styles';
import { getAuth, signOut } from 'firebase/auth';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CSImg from '../assets/banner.jpg';
import { AuthCtx } from '../AuthProvider';
import { UIContext } from '../UIProvider';

export default function SignOutPage() {
  // CONTEXTS
  const { user } = React.useContext(AuthCtx);

  const { darkMode } = useContext(UIContext);

  const navigate = useNavigate();

  useEffect(() => {
    const handleSignOut = async () => {
      if (user) {
        const auth = getAuth();
        await signOut(auth);
      }
      navigate('/');
    };
    handleSignOut();
  }, [user, navigate]);

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: darkMode ? 'white' : 'white',
      }}
    ></div>
  );
}
