import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// MUI
import { Button, TextField } from '@mui/material';

interface WindowsLocationType {
  from: Location | undefined;
}

interface TopLeftCancelButtonProps {
  onButtonPress: () => void;
}

const TopLeftCancelButton: FC<TopLeftCancelButtonProps> = ({ onButtonPress }) => {
  return (
    // MUI Icon Button
    <Button
      variant="outlined"
      color="primary"
      size="large"
      sx={{
        // position top right absolutely
        position: 'absolute',
        top: 0,
        left: 0,
      }}
      onClick={onButtonPress}
    >
      Cancel
    </Button>
  );
};

export default TopLeftCancelButton;
