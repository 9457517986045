import React, { useContext, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import './App.css';

// Firebase
import { getAuth, signOut } from 'firebase/auth';

// MUI
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Container, Stack } from '@mui/material';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

// Auth Context
import { AuthCtx } from './AuthProvider';

////////////////////////////////////////////////////////////////////////
////                    CUSTOM COMPONENTS / ASSETS                  ////
////////////////////////////////////////////////////////////////////////

import SettingsModal from './components/SettingsModal';
import SideBar from './components/SideMenu';
import LoadingPage from './pages/LoadingPage';
import UserProfileImage from './components/UserProfileImage';
import { Title } from './components/Shared/Title';
import UnauthorizedPage from './pages/UnauthorizedPage';
import NotificationManager from './pages/NotificationManager';
import { UIContext } from './UIProvider';

export interface Activity {
  type: string;
  code: string;
}

export interface ItemMatrix {
  code: string;
  type: string;
  item: number[];
}

const PageContainer = styled('div')({
  left: '0',
  right: '0',
  bottom: '0',
  display: 'flex',
  flexDirection: 'column',
});

const UserIconButton = styled(IconButton)({
  position: 'absolute',
  right: '0',
  top: '0',
  zIndex: 10,
});

const StyledPageContainer = styled(PageContainer)({
  paddingLeft: '0px',
  overflowY: 'auto',
  flex: 1,
});
export interface SecureLayoutProps {
  title?: string;
  adminRequired?: boolean;
  permissionRequired?: boolean;
  permissionName?: string;
}

function SecureLayout({
  children,
  title,
  adminRequired,
  permissionRequired,
  permissionName,
}: { children: JSX.Element } & SecureLayoutProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const mdTheme = createTheme();
  const { darkMode } = useContext(UIContext);
  const { user, customClaims, loading: loadingUser, permissions } = useContext(AuthCtx);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    navigate('/signout');
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleNavigateToProfilePage = () => {
    navigate('/profile');
  };

  const handleOpen = () => setModalIsOpen(true);

  let location = useLocation();

  if (loadingUser) {
    return <LoadingPage />;
  } else if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (adminRequired && (!customClaims || !customClaims.isAdmin)) {
    return <UnauthorizedPage />;
  }

  if (permissionRequired && permissionName && permissions.indexOf(permissionName) === -1) {
    return <UnauthorizedPage />;
  }

  const setMargin = !!title;

  return (
    <ThemeProvider theme={mdTheme}>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           SETTINGS MODAL                       //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <SettingsModal open={modalIsOpen} setOpen={setModalIsOpen} />

      {/* <SideBar /> */}

      <NotificationManager />

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleNavigateToProfilePage}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOpen}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <UserIconButton size="large" edge="start" color="info" aria-label="menu" onClick={handleClick}>
        <UserProfileImage />
      </UserIconButton>
      <StyledPageContainer
        sx={{
          top: setMargin ? '160px' : 0,
          background: darkMode ? '#0a0e0f' : 'white',
        }}
      >
        <Container maxWidth="xl" sx={{ position: 'relative' }}>
          {title ? (
            <Title gutterBottom sx={{ color: darkMode ? 'white' : 'black' }}>
              {title}
            </Title>
          ) : null}
        </Container>
        <Stack maxWidth={undefined} sx={{ position: 'relative', flex: 1, overflowY: 'auto' }}>
          {children}
        </Stack>
      </StyledPageContainer>
    </ThemeProvider>
  );
}

export default SecureLayout;
