import { FC, useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { auth, storage } from '../Firebase';
import defaultAvatar from '../assets/avatar-default.png';

interface UserProfileImageProps {
  size?: number;
  imageStoragePath?: string;
}

const UserProfileImage: FC<UserProfileImageProps> = ({ size = 40, imageStoragePath }) => {
  const [imageURL, setImageURL] = useState<string>();

  const getImageURL = async (path: string) => {
    try {
      const downloadURL = await getDownloadURL(ref(storage, path));
      setImageURL(downloadURL);
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    if (imageStoragePath) {
      getImageURL(imageStoragePath);
    } else if (auth.currentUser) {
      const uid = auth.currentUser.uid;
      const path = `user_images/${uid}/${uid}.jpg`;
      getImageURL(path);
    }
  }, [imageStoragePath]);

  return (
    <div
      style={{
        marginLeft: 5,
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        height={`${size}`}
        width={`${size}`}
        style={{ borderRadius: `${size}px` }}
        src={imageURL || defaultAvatar}
        alt={'Profile'}
      />
    </div>
  );
};

export default UserProfileImage;
