import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';

// FIREBASE
// Importing the firebase module will initialize the firebase app client
import './Firebase';

// MUI
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

// Context
import { AuthProvider } from './AuthProvider';
import { UIProvider } from './UIProvider';

// IMPORT PAGES
import SecureLayout from './SecureLayout';
import SignInPage from './pages/SignInPage';
import NotFoundPage from './pages/NotFoundPage';
import IndexPage from './pages/IndexPage';
import { DataProvider } from './pages/DataProvider';
import ChatPage from './pages/ChatPage';
import LanguageSelectionPage from './pages/LanguageSelectionPage';
import SignOutPage from './pages/SignOutPage';
import ProfilePage from './pages/ProfilePage';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <UIProvider>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                           LANDING PAGE                         //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/"
                element={
                  <DataProvider>
                    <IndexPage />
                  </DataProvider>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                           SIGN IN PAGE                         //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route path="/login" element={<SignInPage />} />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                           SIGN OUT PAGE                        //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route path="/signout" element={<SignOutPage />} />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                     LANGUAGE SELECTION PAGE                    //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/learn"
                element={
                  <SecureLayout>
                    <DataProvider>
                      <LanguageSelectionPage />
                    </DataProvider>
                  </SecureLayout>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                            CHAT PAGE                           //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/:language"
                element={
                  <SecureLayout>
                    <DataProvider>
                      <ChatPage />
                    </DataProvider>
                  </SecureLayout>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                          PROFILE PAGE                          //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route
                path="/profile"
                element={
                  <SecureLayout>
                    <DataProvider>
                      <ProfilePage />
                    </DataProvider>
                  </SecureLayout>
                }
              />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                          NOT FOUND PAGE                        //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </UIProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
