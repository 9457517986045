import { IconButton, InputAdornment, OutlinedInput, styled, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChatMessage, startChat, sendMessage, Chat, TemporaryChatMessage } from '../API';
import { AuthCtx } from '../AuthProvider';
import { UIContext } from '../UIProvider';

import HelpIcon from '@mui/icons-material/HelpOutlined';
import SendIcon from '@mui/icons-material/Send';
import InfoIcon from '@mui/icons-material/Info';
import RecommendationView from './RecommendationView';
import ChatInputFiled from '../components/ChatInputField';
import { DataCtx } from './DataProvider';
const CustomCard = styled('div')({
  padding: '30px',
});

interface ChatPageCardProps {
  position: 'left' | 'right';
  text: string;
  translation?: string;
  message_variant?: string;
  blunder?: boolean;
}

const ChatPageCard: FC<ChatPageCardProps> = ({ position, text, translation, message_variant, blunder }) => {
  const { darkMode } = React.useContext(UIContext);

  const botCardBackground = darkMode ? '#252c2e' : 'white';
  const botCardFontColor = darkMode ? 'white' : 'black';

  const userCardFontColor = darkMode ? 'black' : 'white';

  return (
    <div style={{ display: 'flex' }}>
      {
        // If the message is from the user, show the info icon
        translation && position === 'right' && (
          <>
            <div style={{ flex: 1 }}></div>
            <Tooltip title={translation} enterTouchDelay={50} leaveTouchDelay={3000}>
              <IconButton sx={{ color: 'white' }} disableRipple>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </>
        )
      }
      <CustomCard
        sx={{
          boxShadow: !darkMode ? 'rgb(32 54 86 / 15%) 0px 8px 20px' : undefined,
          borderRadius: position === 'left' ? '20px 20px 20px 0px' : '20px 20px 0px 20px',
          border: blunder ? '2px solid crimson' : undefined,
          marginLeft: position === 'left' ? '0px' : 'auto',
          background: position === 'right' ? '#48a5c3' : botCardBackground,
          color: position === 'right' ? userCardFontColor : botCardFontColor,
        }}
      >
        <Typography>{text}</Typography>
      </CustomCard>
      {
        // If the message is from the bot, show the info icon
        translation && position === 'left' && (
          <Tooltip title={translation} enterTouchDelay={50} leaveTouchDelay={3000}>
            <IconButton sx={{ color: 'white' }} disableRipple>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )
      }
    </div>
  );
};

export default function ChatPage() {
  const { setInputText } = React.useContext(DataCtx);
  const { darkMode, windowSize } = React.useContext(UIContext);

  const [chat, setChat] = React.useState<Chat>();
  const [messages, setMessages] = React.useState<Array<ChatMessage | TemporaryChatMessage>>([]);
  const { user } = React.useContext(AuthCtx);

  // Get the language from URL params
  const { language } = useParams();

  // Ref for the message container
  const messageContainerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async (newMessage: string) => {
    if (user && chat && newMessage !== '') {
      const _newMessage = newMessage;
      let _messages = [
        ...messages,
        { text: _newMessage, chat_id: chat.id, uid: user.uid, timestamp: new Date().getTime() },
      ];
      setMessages(_messages);
      setInputText('');
      setTimeout(async () => {
        scrollToBottom();
        const response = await sendMessage(_newMessage, chat.uuid);
        _messages = [..._messages, response.response];
        setMessages(_messages);
        scrollToBottom();
      }, 200);
    }
  };

  // Use Effect to listen to window resize
  useEffect(() => {
    const fetchMessages = async () => {
      if (language) {
        // Check if language is supported
        const lang = language.toUpperCase();
        if (['DE', 'EN', 'SV', 'ES', 'FI', 'RU', 'NL', 'IT', 'TR'].includes(lang)) {
          const startChartResponse = await startChat(lang);
          setChat(startChartResponse.chat);
          setMessages(startChartResponse.messages);
          setTimeout(async () => {
            scrollToBottom();
          }, 500);
        } else {
          console.log('Language not supported');
        }
      }
    };
    fetchMessages();
  }, [language]);

  const getLanguageNameByISOCode = (code: string) => {
    switch (code.toUpperCase()) {
      case 'DE':
        return 'German';
      case 'EN':
        return 'English';
      case 'SV':
        return 'Swedish';
      case 'ES':
        return 'Spanish';
      case 'FI':
        return 'Finnish';
      default:
        return 'Language not supported';
    }
  };

  return (
    <Stack
      sx={{
        flex: 1,
        px: 2,
        alignItems: windowSize.width > 600 ? 'center' : undefined,
      }}
    >
      <Stack
        sx={{
          width: windowSize.width > 600 ? '600px' : undefined,
          height: '80px',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: darkMode ? 'white' : 'black',
          }}
        >
          {/* {language && getLanguageNameByISOCode(language)} */}
          {chat && chat.bot.name}
        </Typography>
      </Stack>
      <Stack
        sx={{
          flex: 1,
          position: 'relative',
          width: windowSize.width > 600 ? '600px' : undefined,
        }}
      >
        <Stack
          ref={messageContainerRef}
          sx={{
            display: 'relative',
            // absolute positioning
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // vertical scrolling
            overflowY: 'auto',
            // Customize the scrollbar
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
          }}
          p={2}
          spacing={2}
        >
          {messages.map(message => (
            <ChatPageCard
              position={message.uid === 'bot' ? 'left' : 'right'}
              text={message.text}
              translation={message.translation}
              blunder={message.isBlunder}
            />
          ))}
        </Stack>
      </Stack>
      <Stack
        sx={{
          width: windowSize.width > 600 ? '600px' : undefined,
          background: darkMode ? '#202426' : undefined,
          p: 1,
          mb: 1,
        }}
      >
        {chat && <ChatInputFiled chat={chat} onSendMessage={handleSendMessage} />}
      </Stack>
    </Stack>
  );
}
