import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { auth } from '../Firebase';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '15px',
  p: 5,
};

export default function TransitionsModal({ open, setOpen }: { open: boolean; setOpen: (isOpen: boolean) => void }) {
  const [token, setToken] = React.useState<string | undefined>();
  const [uid, setUid] = React.useState<string | undefined>();
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const getToken = async () => {
      if (auth.currentUser) {
        const token = await auth.currentUser.getIdToken();
        setUid(auth.currentUser.uid);
        setToken(token);
      }
    };
    getToken();
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              SETTINGS
            </Typography>
            <Typography sx={{ mt: 2, fontWeight: 500 }}>User ID (UID):</Typography>
            <Typography sx={{ mt: 1, wordBreak: 'break-word' }} variant={'body2'}>
              {uid}
            </Typography>
            <Typography sx={{ mt: 2, fontWeight: 500 }}>Token:</Typography>
            <Typography sx={{ mt: 1, wordBreak: 'break-word' }} variant={'body2'}>
              {token}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
