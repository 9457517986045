import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { AuthCtx } from '../AuthProvider';

interface DataCtxProps {
  isLoading: boolean;
  setLoading: (state: boolean) => void;
  fetchData: () => void;

  // Chat UI
  inputText: string;
  setInputText: (text: string) => void;
}

export const DataCtx = createContext({} as DataCtxProps);

export const DataProvider: React.FC = ({ children }) => {
  const { user } = useContext(AuthCtx);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      if (user) {
        setLoading(false);
      }
    },
    [user],
  );

  useEffect(() => {
    fetchData();
  }, [user, fetchData]);

  return (
    <DataCtx.Provider
      value={{
        isLoading,
        setLoading,
        fetchData: fetchData,
        inputText,
        setInputText,
      }}
    >
      {children}
    </DataCtx.Provider>
  );
};
